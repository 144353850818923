<template>
  <div class=container>
      <table width="100%" border="0" cellspacing="30" cellpadding="0">
          <tbody><tr>
            <td><div align="center"><font size="+2">Gallery of Separation Results</font></div></td>
          </tr>
          <tr>
            <td>Here, we show several examples of our separation results. In each case, we mention the separation method we have used. In all cases, the scene image is the sum of the computed direct and global images. However, to bring out the details, for some of the scenes we have brightened the direct and global images by a scale factor (between 1 and 2) with respect to the scene image. <strong>By clicking on each of the thumbnail images shown below, you can see the corresponding high resolution image.</strong><br>
            </td>
          </tr>
        <router-link :to="{path:'/projects/categories/project', query:{cid:'Computational Imaging',pid:'Fast Separation of Direct and Global Images'}}">(Back to Main Project Page) </router-link>
          <tr>
            <td><table width="100%" border="1" cellspacing="0" cellpadding="0">
                <tbody><tr>
                  <td><table width="100%" border="0" cellspacing="10" cellpadding="0">
                      <tbody><tr>
                        <td width="47%"><strong>Marble : From BSSRDF to BRDF</strong></td>
                        <td width="53%"><div align="right"><strong>Method : Checkerboard<br>
                        </strong></div></td>
                      </tr>
                    </tbody></table>
                      <div align="right"></div></td>
                </tr>
                <tr>
                  <td><table width="100%" border="0" cellspacing="10" cellpadding="0">
                      <tbody><tr>
                        <td><a href="https://cave.cs.columbia.edu/old/projects/separation/images_lg/Marble_Resized_Scene_04.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/Separation_GalleryTwo_files/image6661.jpg" width="202" height="56" border="0"></a></td>
                        <td><a href="https://cave.cs.columbia.edu/old/projects/separation/images_lg/Marble_Resized_Direct_04.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/Separation_GalleryTwo_files/image6671.jpg" width="202" height="56" border="0"></a></td>
                        <td><a href="https://cave.cs.columbia.edu/old/projects/separation/images_lg/Marble_Resized_Global_04.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/Separation_GalleryTwo_files/image6541.jpg" width="203" height="56" border="0"></a></td>
                      </tr>
                      <tr>
                        <td>&nbsp;</td>
                        <td><div align="center">Resolution Scale = 1</div></td>
                        <td>&nbsp;</td>
                      </tr>
                      <tr>
                        <td><a href="https://cave.cs.columbia.edu/old/projects/separation/images_lg/Marble_Resized_Scene_08.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/Separation_GalleryTwo_files/image6581.jpg" width="202" height="55" border="0"></a></td>
                        <td><a href="https://cave.cs.columbia.edu/old/projects/separation/images_lg/Marble_Resized_Direct_08.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/Separation_GalleryTwo_files/image6561.jpg" width="202" height="55" border="0"></a></td>
                        <td><a href="https://cave.cs.columbia.edu/old/projects/separation/images_lg/Marble_Resized_Global_08.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/Separation_GalleryTwo_files/image6531.jpg" width="203" height="56" border="0"></a></td>
                      </tr>
                      <tr>
                        <td>&nbsp;</td>
                        <td><div align="center">Resolution Scale = 1/2</div></td>
                        <td>&nbsp;</td>
                      </tr>
                      <tr>
                        <td><a href="https://cave.cs.columbia.edu/old/projects/separation/images_lg/Marble_Resized_Scene_16.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/Separation_GalleryTwo_files/image6521.jpg" width="202" height="56" border="0"></a></td>
                        <td><a href="https://cave.cs.columbia.edu/old/projects/separation/images_lg/Marble_Resized_Direct_16.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/Separation_GalleryTwo_files/image6571.jpg" width="202" height="56" border="0"></a></td>
                        <td><a href="https://cave.cs.columbia.edu/old/projects/separation/images_lg/Marble_Resized_Global_16.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/Separation_GalleryTwo_files/image2981.jpg" width="203" height="55" border="0"></a></td>
                      </tr>
                      <tr>
                        <td>&nbsp;</td>
                        <td><div align="center">Resolution Scale = 1/4</div></td>
                        <td>&nbsp;</td>
                      </tr>
                      <tr>
                        <td><a href="https://cave.cs.columbia.edu/old/projects/separation/images_lg/Marble_Resized_Scene_24.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/Separation_GalleryTwo_files/image328.jpg" width="202" height="55" border="0"></a></td>
                        <td><a href="https://cave.cs.columbia.edu/old/projects/separation/images_lg/Marble_Resized_Direct_24.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/Separation_GalleryTwo_files/image3181.jpg" width="202" height="55" border="0"></a></td>
                        <td><a href="https://cave.cs.columbia.edu/old/projects/separation/images_lg/Marble_Resized_Global_24.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/Separation_GalleryTwo_files/image3211.jpg" width="203" height="55" border="0"></a></td>
                      </tr>
                      <tr>
                        <td>&nbsp;</td>
                        <td><div align="center">
                          Resolution Scale = 1/6
                          </div></td>
                        <td>&nbsp;</td>
                      </tr>
                      <tr>
                        <td width="32%"><div align="center"><strong>Scene</strong></div></td>
                        <td width="35%"><div align="center"><strong>Direct</strong></div></td>
                        <td width="33%"><div align="center"><strong>Global</strong></div></td>
                      </tr>
                    </tbody></table>
                      <table width="100%" border="0" cellspacing="10" cellpadding="0">
                        <tbody><tr>
                          <td>This  example shows the separation results for a marble tile for different  resolutions of imaging and illumination. For scale = 1, the resolution  of the camera was set at the native resolution of 1024x768 and the  separation was done with a checkerboard pattern with checkers of 4x4  projector pixels. For lower scales of 1/n, the resolution of the camera  was reduced by aggregating n x n pixels and the checker size of the  illumination pattern was increased to 4n x 4n. The images shown above  correspond to scales 1, 1/2, 1/4 and 1/6 from top to bottom. At scale =  1, we see a slight direct component and a dominant global component.  In contrast, at scale = 1/6, we see that the global component is nearly  zero everywhere and the direct component is almost equal to the  original image. This example shows how the BSSRDF of a translucent  object dominates at fine scales, but reduces to a BRDF as one goes to  coarser scales.</td>
                        </tr>
                        <tr>
                          <td>&nbsp;</td>
                        </tr>
                    </tbody></table></td>
                </tr>
            </tbody></table></td>
          </tr>
          


          <tr>
            <td>&nbsp;</td>
          </tr>
        </tbody></table>
  </div>
</template>

<script>
export default {

}
</script>

<style>
.container{
    width:70%;
    margin-left:auto;
    margin-right:auto;
    text-align: left;
}
</style>